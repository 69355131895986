export default {
  selectors: {
    tablist: '.cmp-tabs__tablist',
    prevWrapper: '.batcom-tabs__button-wrapper--prev',
    prevButton: '.batcom-tabs__button--prev',
    nextWrapper: '.batcom-tabs__button-wrapper--next',
    nextButton: '.batcom-tabs__button--next',
  },
  classes: {
    visible: 'visible',
    hidden: 'hidden',
  },
  customProps: {
    scrollOffset: 1,
    scrollLeft: 200,
  },
};
