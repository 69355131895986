import { register } from '@netcentric/component-loader';
import { deepMerge } from 'commons/libs/deepMerge';
import config from './batcom-tabs.config';

export default class Tabs {
  constructor(el, params) {
    this.el = el;
    const cfg = deepMerge({}, config, params);
    this.selectors = cfg.selectors;
    this.classes = cfg.classes;
    this.customProps = cfg.customProps;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEvenListeners();
    this.setResizeObserver();
    this.handleNav();
  }

  setRefs() {
    this.tablist = this.el.querySelector(this.selectors.tablist);
    this.prevWrapper = this.el.querySelector(this.selectors.prevWrapper);
    this.prevButton = this.el.querySelector(this.selectors.prevButton);
    this.nextWrapper = this.el.querySelector(this.selectors.nextWrapper);
    this.nextButton = this.el.querySelector(this.selectors.nextButton);
  }

  setEvenListeners() {
    this.tablist.addEventListener('scroll', () => {
      this.handleNav();
    });

    this.prevWrapper.addEventListener('click', () => {
      this.tablist.scrollLeft -= this.customProps.scrollLeft;
      this.handleNav();
    });

    this.nextWrapper.addEventListener('click', () => {
      this.tablist.scrollLeft += this.customProps.scrollLeft;
      this.handleNav();
    });
  }

  setResizeObserver() {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(() => {
        this.handleNav();
      });
    });

    resizeObserver.observe(this.el);
  }

  handleNav() {
    const tablistOffsetWidth = this.tablist.offsetWidth;
    const tablistScrollWidth = this.tablist.scrollWidth;
    const tablistNotVisibleWidth = tablistScrollWidth - tablistOffsetWidth;
    const tablistScrollLeft = this.tablist.scrollLeft;
    const tablistEndOffset = tablistNotVisibleWidth - this.customProps.scrollOffset;

    if (tablistNotVisibleWidth <= 0) {
      this.prevWrapper.classList.remove(this.classes.visible);
      this.prevButton.classList.remove(this.classes.visible);
      this.nextWrapper.classList.add(this.classes.hidden);
      this.nextButton.classList.remove(this.classes.visible);
    } else if (tablistScrollLeft <= this.customProps.scrollOffset) {
      this.prevWrapper.classList.remove(this.classes.visible);
      this.prevButton.classList.remove(this.classes.visible);
      this.nextWrapper.classList.remove(this.classes.hidden);
      this.nextButton.classList.add(this.classes.visible);
    } else if (tablistScrollLeft < tablistEndOffset) {
      this.prevWrapper.classList.add(this.classes.visible);
      this.prevButton.classList.add(this.classes.visible);
      this.nextWrapper.classList.remove(this.classes.hidden);
      this.nextButton.classList.add(this.classes.visible);
    } else if (tablistScrollLeft >= tablistEndOffset) {
      this.prevWrapper.classList.add(this.classes.visible);
      this.prevButton.classList.add(this.classes.visible);
      this.nextWrapper.classList.add(this.classes.hidden);
      this.nextButton.classList.remove(this.classes.visible);
    }
  }
}

register({ Tabs });
